define('modules/orderManagement/controllers/OrderManagementController',[
	'app',
	'angular',
	'settings',
	'modules/basemodule/controllers/EntityListController',
	'modules/orderManagement/controllers/OrderManagementSettingsFormController',
	'services/ShippingLabelService',
	'services/NavigationService',
	'services/OrderService',
	'filters/TimestampFilter',
], function (module) {
	'use strict';
	module.controller('OrderManagementController', [
		'$timeout',
		'$controller',
		'$sce',
		'$scope',
		'$q',
		'$translate',
		'ShippingLabelService',
		'NavigationService',
		'BASE_TEMPLATES_PATH',
		'NotificationService',
		'OrderService',
		'OrderManagementService',
		'ShopService',
		function (
			$timeout,
			$controller,
			$sce,
			$scope,
			$q,
			$translate,
			ShippingLabelService,
			NavigationService,
			BASE_TEMPLATES_PATH,
			NotificationService,
			OrderService,
			OrderManagementService,
			ShopService
		) {
			var generateArchivedParams = function (state) {
				var archivedParams = [
					{
						name: 'archive',
						value: state,
					},
				];
				return archivedParams;
			};

			$scope.archiveViewActive = false;

			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.hideAddDeleteButtons = true;

			$scope.tableOptions.fields = [
				{
					id: 'ORDER_NUMBER',
					heading: 'FIELDS.ORDER_NUMBER',
					template: BASE_TEMPLATES_PATH + '/orderManagement/partials/order-number-col.html',
					attribute: 'orderNumber',
				},
				{
					id: 'TIMESTAMP',
					heading: 'FIELDS.ORDER_DATE',
					template: BASE_TEMPLATES_PATH + '/orderManagement/partials/order-date-col.html',
					attribute: 'orderDate',
				},
				{
					id: 'INVOICE_NAME',
					heading: 'CONTROLLERS.BILL_RECIPIENT',
					template: BASE_TEMPLATES_PATH + '/orderManagement/partials/invoice-name-col.html',
					attribute: 'name',
				},
				{
					id: 'sum',
					heading: 'CONTROLLERS.TOTAL',
					template: BASE_TEMPLATES_PATH + '/orderManagement/partials/total-col.html',
					attribute: 'total',
					searchDisabled: true,
					orderDisabled: true,
				},
				{
					id: 'STATUS',
					heading: 'FIELDS.STATUS',
					template: BASE_TEMPLATES_PATH + '/orderManagement/partials/status-col.html',
					attribute: 'orderStateIds',
					searchDisabled: true,
					orderDisabled: true,
				},
				{
					id: 'CUSTOMER_TYPE',
					heading: 'FIELDS.CUSTOMER_TYPE',
					template: BASE_TEMPLATES_PATH + '/orderManagement/partials/customer-type-col.html',
					attribute: 'customerType',
					searchDisabled: true,
					orderDisabled: true,
				},
				{
					id: 'PAYMENT_METHOD_NAME',
					heading: 'CONTROLLERS.PAYMENT_METHOD_NAME',
					attribute: 'paymentMethodName',
				},
			];

			$scope.formState = 'root.ordermanagement';

			$scope.init('Order').then(function () {
				if ($scope.isOrderManagementActive()) {
					$scope.topBarLeftTemplate = BASE_TEMPLATES_PATH + '/orderManagement/partials/top-bar-left.html';
					$scope.topBarTemplate = BASE_TEMPLATES_PATH + '/orderManagement/partials/top-bar.html';
					$scope.tableOptions.optionalFields = [
						{
							id: 'ORIGIN',
							heading: 'CONTROLLERS.CHANNEL',
							template: BASE_TEMPLATES_PATH + '/orderManagement/partials/order-origin-col.html',
							searchDisabled: true,
							orderDisabled: true,
						},
						{
							id: 'TRACKING',
							heading: 'CONTROLLERS.TRACKING_NUMBER',
							template: BASE_TEMPLATES_PATH + '/orderManagement/partials/tracking-col.html',
							attribute: 'trackingNumber',
						},
						{
							id: 'DELIVERY',
							heading: 'CONTROLLERS.DELIVERY_NUMBER',
							template: BASE_TEMPLATES_PATH + '/orderManagement/partials/delivery-col.html',
							attribute: 'deliveryNumber',
						},
						{
							id: 'INVOICE',
							heading: 'CONTROLLERS.INVOICE_NUMBER',
							template: BASE_TEMPLATES_PATH + '/orderManagement/partials/invoice-col.html',
							attribute: 'invoiceNumber',
						},
						{
							id: 'ORDER_CONFIRMATION',
							heading: 'CONTROLLERS.ORDER_CONFIRMATION_NUMBER',
							template: BASE_TEMPLATES_PATH + '/orderManagement/partials/order-confirmation-col.html',
							attribute: 'orderConfirmationNumber',
						},
						{
							id: 'INVOICE_NUMBER',
							heading: 'CONTROLLERS.INVOICE_NUMBER',
							template: BASE_TEMPLATES_PATH + '/orderManagement/partials/invoice-number-col.html',
							attribute: 'invoiceNumber',
						},
						{
							id: 'DELIVERY_NUMBER',
							heading: 'CONTROLLERS.DELIVERY_NUMBER',
							template: BASE_TEMPLATES_PATH + '/orderManagement/partials/delivery-number-col.html',
							attribute: 'deliveryNumber',
						},
						{
							id: 'ORDER_CONFIRMATION_NUMBER',
							heading: 'CONTROLLERS.ORDER_CONFIRMATION_NUMBER',
							template:
								BASE_TEMPLATES_PATH + '/orderManagement/partials/order-confirmation-number-col.html',
							attribute: 'orderConfirmationNumber',
						},
					];
				}

				$scope.orderManagement = {};

				$scope.disabled = {
					invalidCredentials: true,
					noShopSelected: true,
				};
			});

			$scope.editConfig = function () {
				NavigationService.changeState('root.ordermanagementsetting');
			};

			$scope.expressTimeDefault = '1200';

			$scope.settings = {
				carrier: null,
				custom: null,
				expressTime: $scope.expressTimeDefault,
			};

			$scope.expressCarrierSuffix = '_EXPRESS';

			var loadCustomCarriers = function () {
				$scope.customCarriers = [
					{
						display: 'CONTROLLERS.USE_CONFIGURATION',
						value: null,
					},
				];

				ShopService.findShop().then(function () {
					var shop = ShopService.getOne();

					if (!angular.isArray(shop.shopShippingMethods) || shop.shopShippingMethods.length === 0) {
						return;
					}

					for (var i = 0; i < shop.shopShippingMethods.length; i++) {
						if (!shop.shopShippingMethods[i].isActive) {
							continue;
						}

						$scope.customCarriers.push({
							display: shop.shopShippingMethods[i].name,
							value: shop.shopShippingMethods[i].name,
						});
					}
				});
			};

			loadCustomCarriers();

			$scope.expressTimes = {
				DPD: [
					{
						display: '8:30 (DPD)',
						value: '830',
					},
					{
						display: '10:00 (DPD)',
						value: '1000',
					},
					{
						display: '12:00 (DPD, DHL, UPS)',
						value: '1200',
					},
					{
						display: '18:00 (DPD)',
						value: '1800',
					},
				],
				DHL: [
					{
						display: '9:00 (DHL, UPS)',
						value: '900',
					},
					{
						display: '12:00 (DPD, DHL, UPS)',
						value: '1200',
					},
				],
			};

			$scope.$watch('settings.carrier', function (carrier) {
				$scope.settings.expressTime = carrier === null ? null : $scope.expressTimeDefault;
			});

			$scope.validateOrders = function (action) {
				var areOrdersValid = true;

				var isTrackingNumberRequired = action === 'print' || action === 'cancel';

				if ($scope.selected.items.length === 0) {
					areOrdersValid = false;
				} else {
					for (var i = 0; i < $scope.selected.items.length; i++) {
						var trackingNumber = $scope.selected.items[i].trackingNumber;

						var isTrackingNumberValid = angular.isString(trackingNumber) && trackingNumber.length > 0;

						if (
							(isTrackingNumberRequired && !isTrackingNumberValid) ||
							(!isTrackingNumberRequired && isTrackingNumberValid)
						) {
							areOrdersValid = false;
							break;
						}
					}
				}

				return areOrdersValid;
			};

			$scope.createShippingLabels = function () {
				var ordersToProcess = [];
				for (var i = 0; i < $scope.selected.items.length; i++) {
					var order = $scope.selected.items[i];
					if (order.trackingNumber === undefined || order.trackingNumber === '') {
						ordersToProcess.push(order);
					} else {
						NotificationService.notifyGeneralError({
							statusText: 'CONTROLLERS.ERRORS_OCCURED',
							msg: 'CONTROLLERS.LABEL_IS_CREATED',
						});
						return;
					}
				}

				var customSettings = {};
				if ($scope.settings.carrier !== null) {
					customSettings.carrier = $scope.settings.carrier;

					if (customSettings.carrier.indexOf($scope.expressCarrierSuffix) !== -1) {
						customSettings.carrier = customSettings.carrier.replace($scope.expressCarrierSuffix, '');
						customSettings.expressTime = $scope.settings.expressTime;
					}
				}

				customSettings.custom = $scope.settings.custom;

				OrderManagementService.createBulkShippingLabels(ordersToProcess, $scope.items, customSettings);
			};

			var setShippingLinkHTML = function (html) {
				$scope.shippingLinkHTML = $sce.trustAsHtml(html);
			};

			$scope.printShippingLabels = function () {
				setShippingLinkHTML('');

				var trackingNumbers = [];
				$scope.selected.items.forEach(function (order) {
					if (order.trackingNumber.indexOf(',') !== -1) {
						trackingNumbers = trackingNumbers.concat(order.trackingNumber.split(','));
					} else {
						trackingNumbers.push(order.trackingNumber);
					}
				});

				OrderManagementService.printShippingLabel(trackingNumbers).then(function (encodedString) {
					if (encodedString) {
						var link = OrderManagementService.getObjectURLFromEncodedString(encodedString);
						$translate('CONTROLLERS.OPEN_PDF').then((res) => {
							setShippingLinkHTML(
								"<a target='_blank' id='open-pdf-btn' class='' href='" + link + "'>" + res + '</a>'
							);
						});
					}
				});
			};

			$scope.cancelShippingLabels = function () {
				$scope.shippingLinkHTML = '';

				OrderManagementService.clearOrderNotifications();

				$scope.labelCancelPromises = [];
				var carrier =
					$scope.settings.carrier === null
						? null
						: $scope.settings.carrier.replace($scope.expressCarrierSuffix, '');
				$scope.selected.items.forEach(function (order) {
					$scope.labelCancelPromises.push(
						OrderManagementService.cancelShippingLabel(order, carrier).then(function () {})
					);
				});
				$q.all($scope.labelCancelPromises).then(function () {
					$scope.selected.items = [];
					$scope.disabled.noSelection = true;
					$scope.entityService.findAll();
				});
			};

			$scope.generateOrder = function () {};

			$scope.reorder = function () {
				if ($scope.selected.items.length == 0) {
					return;
				}

				if ($scope.selected.items.length > 1) {
					NotificationService.notifyGeneralError({
						statusText: 'CONTROLLERS.ORDER_CREATE_ONLY',
					});
					return;
				}
				OrderService.reorder($scope.selected.items[0]).then(
					function (response) {
						//success
						//$state.go("root.ordermanagementReorder", {shoppingCartId: "1321"});

						NavigationService.changeStateWithObject('root.ordermanagementReorder', {
							shoppingCartId: response.id,
						});
					},
					function (response) {
						//error
						console.error(response);
					}
				);
			};

			$scope.switchDisplayOrderArchive = function () {
				$scope.archiveViewActive = !$scope.archiveViewActive;
				$scope.entityService.setViewSpecificParams(generateArchivedParams($scope.archiveViewActive));
				$scope.entityService.findAll();
			};

			$scope.delOrder = function () {};

			$scope.isOrderManagementActive = function () {
				//TODO: test if the user has bought ordermanagement
				return true;
			};

			$scope.completeDateRangeModalController = function ($scope) {
				var dateToday = new Date();
				dateToday.setHours(0, 0, 0, 0);

				$scope.dateFrom = dateToday;
				$scope.dateTo = dateToday;

				$scope.completeShipping = function () {
					OrderManagementService.closeModal();

					setShippingLinkHTML('');

					OrderManagementService.completeShipping(
						$filter('date')($scope.dateFrom, 'MMddyyyy'),
						$filter('date')($scope.dateTo, 'MMddyyyy')
					).then(function (encodedString) {
						if (encodedString) {
							var link = OrderManagementService.getObjectURLFromEncodedString(encodedString);
							$translate('CONTROLLERS.DELIVERY_NOTE').then((res) => {
								setShippingLinkHTML(
									"<a target='_blank' id='open-pdf-btn' class='' href='" + link + "'>" + res + '</a>'
								);
							});
						}
					});
				};
			};
		},
	]);
});

